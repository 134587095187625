/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/leaflet@1.9.4/dist/leaflet-src.min.js
 * - /npm/leaflet.markercluster@1.5.3/dist/leaflet.markercluster-src.min.js
 * - /npm/leaflet-active-area@1.2.1/src/leaflet.activearea.min.js
 * - /npm/leaflet-gpx@1.0.0/gpx.min.js
 * - /npm/beautifymarker@1.0.9/leaflet-beautify-marker-icon.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
